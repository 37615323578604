const bonbon = new URL('../img/bonbon.png', import.meta.url);
const dice = new URL('../img/dice.png', import.meta.url);
const na = new URL('../img/not-applicable.png', import.meta.url);
const tank = new URL('../img/tank.png', import.meta.url);
const heart = new URL('../img/heart.png', import.meta.url);

export function Bonbon() {
  return <img src={bonbon} alt="bonbon" />;
}
export function Dice() {
  return <img src={dice} alt="dice" />;
}
export function Na() {
  return <img src={na} alt="na" />;
}
export function Tank() {
  return <img src={tank} alt="tank" />;
}
export function Heart() {
  return <img src={heart} alt="heart" />;
}
