import React from "react";

import { Button } from "./components/Button";
import { Bonbon, Dice, Tank, Na, Heart } from "./components/Image";
import Waiting from "./Waiting";

export class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = { text: '', win: false, answered: 0 };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    this.setState({ text: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
   

    if (this.state.text === 'charbonnade') {
      this.setState(state => ({
        win: true,
        answered: 1
      }));
    } else {
      this.setState(state => ({
        win: false,
        answered: state.answered + 1
      }));
    }
  }

  render () {

    const isWin = this.state.win;
    let notice;
    if (isWin === true) {
      notice = <p>Ouaiiiiis Bravo, ça te dit ? Je t'aime <Heart /></p>
    } else if (isWin === false && this.state.answered > 0 ) {
      notice = <p>Oh allez ré-essaie</p>
    }

    return (
      <div className="min-h-screen bg-gray-50 py-6 flex flex-col justify-center relative overflow-hidden sm:py-12">
        <div className="relative px-6 pt-10 pb-8 bg-white shadow-xl ring-1 ring-gray-900/5 sm:max-w-xl sm:mx-auto sm:rounded-lg sm:px-10 text-center space-y-4">
          <h1 className="font-heading text-5xl text-pink-400">Mon Bébé</h1>
          <Waiting />
          <p>Est-ce que tu es libre ce mercredi 9 février 2022 à partir de 19h ?</p>
          <br/>
          <p>Résout le rébus suivant et tu as gagné:</p>
          <div className="columns-4">
          <Tank />
          <div><Bonbon /> /2</div>
          <Na />
          <Dice />
          </div>
          <form onSubmit={this.handleSubmit} className="space-y-4">
            <input
              id="answer"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              onChange={this.handleChange}
              value={this.state.text}
            />
            <Button>
              Envoyer
            </Button>
          </form>
          {notice}
        </div>
      </div>
    );
  }
}

