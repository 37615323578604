import { Heart } from "./components/Image";


function Waiting(props) {
  return (
    <>
      <p>Ahahha, je savais que tu reviendrais ici</p>
      <Heart />
    </>
  );
}

export default Waiting;
